import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app.module';
import { environment } from '@environment';

const googleAnalyticsKey = environment.googleAnalyticsMeasurementId;
const googleAnalyticsLink = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsKey}`;

const script = document.createElement('script');
script.src = googleAnalyticsLink;
script.async = true;
document.head.appendChild(script);

script.onload = () => {
    (window as any).dataLayer =  (window as any).dataLayer || [];
    
    (window as any).gtag = function(){
        // eslint-disable-next-line prefer-rest-params
        (window as any).dataLayer.push(arguments);
    };

    (window as any).gtag('js', new Date());
    (window as any).gtag('config', googleAnalyticsKey, { send_page_view: false });
};

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule, { preserveWhitespaces: true })
    .catch((err) => console.warn(err));
