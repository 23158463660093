// for constants shared between both the admin portal and the mobile app.

export class SharedConstants {
    yearOfBirthOptions: { Name: string; Id: number }[] = [];
    static nullOptionOptional = { Name: 'Year of Birth (Optional)', Id: null };
    static nullOptionRequired = { Name: 'Year of Birth', Id: null };
    static validUserUploadedImageExtensions = ['png', 'jpg', 'jpeg'];
    static validUserUploadedVideoExtensions = ['mp4', 'mov', 'avi'];
    static minimumFundamentals = 13;
    static passwordPatternMessage =
        'Passwords must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character (!, $, %, @, #, £, €, *, ?, &, -).';

    public static getChildYearOfBirthOptions(): { Name: string; Id: number }[] {
        const yearOfBirthOptions = [this.nullOptionRequired];
        for (let i = new Date().getFullYear(); i >= 1920; i--) {
            yearOfBirthOptions.push({ Name: i.toString(), Id: i });
        }
        return yearOfBirthOptions;
    }

    public static getGuardianYearOfBirthOptions(): { Name: string; Id: number }[] {
        const yearOfBirthOptions = [this.nullOptionOptional];
        const yearOfBirthOfOldestLivingPerson = 1907; // as of August 10 2024;
        const minRequiredAge = 15;
        for (let i = new Date().getFullYear() - minRequiredAge; i >= yearOfBirthOfOldestLivingPerson; i--) {
            yearOfBirthOptions.push({ Name: i.toString(), Id: i });
        }
        return yearOfBirthOptions;
    }

    public static areEqual(a: unknown, b: unknown): boolean {
        return JSON.stringify(a) === JSON.stringify(b);
    }

    public static deleteAccountMessage = `By deleting your account, all of your data will be removed including your family details, payment information, Fundamentals, and Journal history. 
                    <br />
                    Once deleted, this action can't be undone.`;

    public static cancelSubscriptionMessage = `Your account will remain active until the end of the current pay period.  You won\’t be charged again after this date. Are you sure you wish to cancel your subscription?`;
}

export enum DaysOfWeek {
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
    Sunday = 0,
}
