<div class="row">
    <div class="col-md-4 col-sm-0"></div>
    <div class="col-md-4 col-sm-12">
        <div class="mobile-header container-fluid" style="text-align: center;">
            <img id="logo-img" src="../../assets/OFCLogo.png" style="margin: auto; display: block;" class="img-responsive" />
        </div>
        <div class="page">
            <div class="content">
                <img class="header-img" src="/assets/images/almost-there.png" alt="Almost there" />
                <img src="/assets/images/verification.png" alt="Verification logo" class="verification" />
                <div class="text">
                    <p class="bold-text"> 
                        Look out for your<br/>
                        WELCOME and CONFIRMATION emails
                    </p>
                    <br>
                    <p>
                        Your security is super important to us.<br/>
                        Check your Confirmation email so we can verify your identity.<br/>
                        Once that's complete, you can log in to the<br/>
                        app with your email and password.
                    </p>
                    <br>
                    <p>(Be sure to check your SPAM folder)</p>
                </div>
            </div>
        </div>
    </div>
</div>
